<template>
  <div class="customizable-options">
    
<FormKitLazyProvider>
<button
      class="customizable-options__button button"
      @click="customizableOptionsModal.open()"
    >
      <IconPencil class="icon icon-pencil" />
      <span>{{ $t('Personalize') }}</span>
    </button>

    <LazyUiDialog
      :name="`customizableOptionsModal__${uniqueId}`"
      class="customizable-options__modal"
      dialog-type="responsive"
    >
      <template #dialogHeader>
        {{ $t('Personalize your product', { brand: esProduct?.brand?.label || 'product' }) }}
        <template v-if="price">
          (<i18n-n
            :value="price"
            format="currency"
            scope="global"
          />)
        </template>
      </template>

      <FormKit
        id="customizable-options-form"
        :key="reference"
        type="form"
        :value="data || {}"
        :actions="false"
        name="customizable-options-form"
        @submit="submit"
      >
        <component
          :is="fieldTypes[option.__typename]"
          v-for="option in options"
          :key="option.option_id"
          :option="option"
          :value="data?.[option?.fieldValue?.uid]"
        />

        <template v-if="copyPersonalization?.length">
          <StrapiBlocksText
            v-for="copy in copyPersonalization"
            :key="copy"
            :nodes="copy.Richtext"
          />
        </template>

        <FormKitMessages hidden />

        <div class="formkit-buttons">
          <button
            type="submit"
            class="button button--shop-flow button--full button--center"
          >
            <span>{{ $t('Add to cart') }}</span>
          </button>
        </div>
      </FormKit>
    </LazyUiDialog>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import layoutQuery from '~layers/app/graphql/layout.gql'
import GetCustomizableOptionsQuery from '~/graphql/pdp/getCustomizableOptions.gql'

import type {
  MagentoConfigurableProduct,
  MagentoGroupedProduct,
  MagentoProduct,
} from '~layers/app/types/magento'

const uniqueId = useId()

const emit = defineEmits(['submit'])

const props = withDefaults(defineProps<{
  product: MagentoProduct | MagentoConfigurableProduct | MagentoGroupedProduct
  data?: Record<string, string>
  reference?: string
}>(), {
  data: () => {},
  reference: 'new',
})

const data = toRef(props.data)

const { data: optionsData } = await useAsyncQuery(GetCustomizableOptionsQuery, { productId: props.product.id })

const { public: config } = useRuntimeConfig()
const { data: layoutData } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const options = computed(() => optionsData.value?.amProductOptionsProvider?.items || [])

const copyPersonalization = computed(() => layoutData.value?.copies?.find((item: never) => item.UID === 'pdp__personalization')?.Richtexts)

const esProduct = inject('esProduct', ref(props?.product?.product))

const customizableOptionsModal = useUiDialog(`customizableOptionsModal__${uniqueId}`)

const fieldTypes = {
  AmProtCustomizableFieldOption: defineAsyncComponent(() => import('~layers/app/components/Product/CustomizableOptions/CustomizableFieldOption.vue')),
  AmProtCustomizableDropDownOption: defineAsyncComponent(() => import('~layers/app/components/Product/CustomizableOptions/CustomizableDropDownOption.vue')),
}

const price = computed<number>(() =>
  options.value.reduce((acc: number, item: object) => {
    switch (item.__typename) {
      case 'AmProtCustomizableFieldOption': acc += item.fieldValue.price
        break
      case 'AmProtCustomizableDropDownOption': acc += item.dropdownValue[0].price
        break
    }
    return acc
  }, 0.00),
)

const submit = (formData: never) => {
  emit('submit', Object.keys(formData).map((item) => {
    return {
      id: +item,
      value_string: formData[item],
    }
  }))
  customizableOptionsModal.close()
}
</script>

<style>
@import url("~layers/app/components/Product/CustomizableOptions/CustomizableOptions.css");
</style>
